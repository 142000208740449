<template>
  <div class="liuji_check" v-loading="loading">
    <div class="mark"></div>
    <el-table
      :data="tableData"
      border
      style="width: 100%;max-height:100%;"
      max-height="500"
    >
      <el-table-column prop="stu_name" label="学生姓名" width="180">
      </el-table-column>
      <el-table-column prop="oldClass" label="原班级" width="180">
      </el-table-column>
      <el-table-column prop="newClass" label="新班级" width="180">
      </el-table-column>
      <el-table-column
        prop="jieduan_old"
        width="180"
        label="原课程阶段"
      ></el-table-column>
      <el-table-column
        prop="jieduan_new"
        width="180"
        label="新课程阶段"
      ></el-table-column>
      <el-table-column prop="kind" label="状态"></el-table-column>
    </el-table>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "LiuJiCheck",
  data() {
    return {
      tableData: [],
    };
  },
  computed: {
    ...mapState(["loading"]),
  },
  async created() {
    this.$datas.meta = {...this.$route.query.params};
    let res = await this.$datas.getLiuJiInfo;
    console.log(res);
    this.tableData = res.data;
  },
};
</script>
<style lang="less" scoped>
.liuji_check {
  padding: 0 20px;
  .mark {
    height: 50px;
  }
  .liuji {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    box-sizing: border-box;
  }
}
</style>
